import { api } from '@redux/api/api'
import type { BannerBlockInfoType } from '@redux/features/homePage/types/bannerBlockInfo'
import type { ContextBlocksType } from '@redux/features/routesPage/types/contextBlocksType'
import type { LayoutBuilderType } from '@redux/features/routesPage/types/layoutBuilder'
import type { IGetBodeIdResponse } from '@redux/features/routesPage/types/nodeId'

import type { ExtendBlocksType } from '@Types/blocks/blocks'
import { urlVersionPrefix, urlVersionPrefixV2 } from '@constants/api/api'
import type { CustomContentId } from '@constants/blockList/customContentBlockList'

export const fixedGetNodeIdCacheKey = 'getNodeId'

const routesPageApi = api.injectEndpoints({
  endpoints: build => ({
    getBlockById: build.query<ExtendBlocksType, string>({
      keepUnusedDataFor: 0,
      query: blockId => ({
        keepUnusedDataFor: 0,
        url: urlVersionPrefix + `block/uuid/${blockId}`,
      }),
    }),
    getContextBlock: build.query<
      ContextBlocksType,
      { contentId: CustomContentId; directionStation?: number; nodeId: number | string }
    >({
      query: ({ contentId, directionStation, nodeId }) => ({
        url:
          urlVersionPrefix +
          `node/${nodeId}/context-block/${contentId}${
            directionStation ? `?departure_station=${directionStation}` : ''
          }`,
      }),
    }),
    getLayoutBuilder: build.query<LayoutBuilderType, number | string>({
      query: nodeId => ({
        url: urlVersionPrefix + `entity/node/${nodeId}/layout-builder`,
      }),
    }),
    getNodeId: build.mutation<IGetBodeIdResponse | undefined, string | undefined>({
      query: pathName => ({
        body: { path: pathName || document.location.pathname },
        method: 'POST',
        url: urlVersionPrefixV2 + 'path-lookup',
      }),
      transformResponse: (response: IGetBodeIdResponse, _, arg) =>
        response?.systemPath === (arg || document.location.pathname) ? undefined : response,
    }),
    getRouteInfo: build.query<BannerBlockInfoType, number | string>({
      query: nodeId => ({
        url: urlVersionPrefix + `route-info/${nodeId}`,
      }),
    }),
  }),
})

export const {
  endpoints: routesPageEndpoints,
  useGetBlockByIdQuery,
  useGetContextBlockQuery,
  useGetLayoutBuilderQuery,
  useGetNodeIdMutation,
} = routesPageApi

export const { getBlockById, getContextBlock, getLayoutBuilder, getNodeId } = routesPageEndpoints
