import { authorizationEndpoints } from '@redux/features/authorization/api'
import { logout } from '@redux/features/authorization/thunks'
import { checkoutEndpoints } from '@redux/features/checkout/api'
import { getBannerBlockInfo, homePageEndpoints } from '@redux/features/homePage/api'
import { setNotification } from '@redux/features/modals/modals.slice'
import { myTripsEndpoints } from '@redux/features/myTrips/myTrips.api'
import { orderEndpoints } from '@redux/features/order/api'
import { paymentEndpoints } from '@redux/features/payment/api'
import { getMedia } from '@redux/features/trainData/trainData.api'
import { userEndpoints } from '@redux/features/user/api'
import type { AppDispatch } from '@redux/store'
import { commonAppEndpoints } from '@reduxCommonApp/commonApp.api'
import type { AnyAction, Middleware } from '@reduxjs/toolkit'
import { isRejectedWithValue } from '@reduxjs/toolkit'

import type { ApiResponseErrorType, BackendResponseErrorType } from '@Types/api/responseError'

import { setIsOpenAuthorizationPopup } from './features/authorization/authorization.slice'
import { preferencesEndpoints } from './features/preferences/preferences.api'
import { routesPageEndpoints } from './features/routesPage/routesPage.api'

const requestsExceptions = new Set([
  checkoutEndpoints.getBookingPaidServiceInfo.name,
  checkoutEndpoints.getBookingOrderItemInfo.name,
  checkoutEndpoints.getBookingService.name,
  checkoutEndpoints.postVoucher.name,
  orderEndpoints.getOrder.name,
  preferencesEndpoints.getClientDetails.name,
  preferencesEndpoints.getCurrentSeatPreference.name,
  preferencesEndpoints.getSeatPreferenceInfo.name,
  authorizationEndpoints.postCheckEmail.name,
  authorizationEndpoints.postLogout.name,
  authorizationEndpoints.postLoginByCode.name,
  authorizationEndpoints.postLogin.name,
  authorizationEndpoints.postAccountReset.name,
  commonAppEndpoints.getConfiguration.name,
  userEndpoints.getCurrentUserInfo.name,
  getBannerBlockInfo.name,
  myTripsEndpoints.getUpcomingStoreOrders.name,
  orderEndpoints.cancelOrder.name,
  getMedia.name,
  paymentEndpoints.getPayment.name,
  paymentEndpoints.getPaymentMethods.name,
  paymentEndpoints.putPayment.name,
  routesPageEndpoints.getContextBlock.name,
  commonAppEndpoints.getCurrencySwitch.name,
])

const requestsLogoutExceptions = new Set([
  checkoutEndpoints.postSearch.name,
  checkoutEndpoints.getBookingOrderItemInfo.name,
  userEndpoints.getCurrentUserInfo.name,
  homePageEndpoints.getBlock.name,
])

export const rtkQueryErrorLogger: Middleware =
  ({ dispatch }) =>
  next =>
  action => {
    if (isRejectedWithValue(action)) {
      handlerErrorAuth(action, dispatch)
      const payload = action.payload as ApiResponseErrorType
      const message = (payload as BackendResponseErrorType).data?.message

      if (!requestsExceptions.has((action.meta.arg as { endpointName: string })?.endpointName)) {
        dispatch(
          setNotification({
            description:
              message ||
              `${payload.status} ${
                (typeof payload.data === 'string' && payload.data) || (payload as { error: string }).error || ''
              }`,
            message: 'somethingWrong',
            type: 'error',
          })
        )
      }
    }

    return next(action)
  }

const handlerErrorAuth = (action: AnyAction, dispatch: AppDispatch) => {
  if (
    action.payload.status === 403 &&
    !requestsLogoutExceptions.has((action.meta.arg as { endpointName: string })?.endpointName)
  ) {
    if (action.meta.arg.endpointName === orderEndpoints.getOrder.name) {
      void dispatch(setIsOpenAuthorizationPopup(true))
    } else if (action.meta.arg.endpointName !== authorizationEndpoints.postLogout.name) {
      void dispatch(logout(false))
    }
  }
}
